<template>
    <div>
        <div class="header">
            <van-icon name="arrow-left" @click="$router.push({path: '/self/service'})"/>
            <div class="title">账单</div>
            <div class="bill">常见问题</div>
        </div>
        <div class="statistics-con">
            <div class="bill">
                <div>全部账单 </div>
                <img src="/images/triangle-down.png" alt="">

            </div>
            <div class="statistics">
                <div>统计</div>
                <van-icon name="arrow" />
            </div>

        </div>
        <div class="time-con">
            <div class="time">
                <div>{{ currentYear }}年{{ currentMonth }}月</div>
                <van-icon name="arrow-down" />
            </div>
            <div class="cost">
                <div>支出¥12595.12</div>
                <div>收入¥3602.10</div> 
            </div>
        </div>
        <div class="list">
            <div v-for="(item, index) in list" :key="index+'index'" class="item" @click="toBillDetail(item.id)">
                <img :src="item.avatar" alt="">
                <div class="text">
                    <div class="first">
                        <div>{{ item.text }}</div>
                        <div style="color:rgb(242, 177, 0);font-weight:550" v-if="item.zrtype == 2">+{{ returnFloat(item.value) }}</div>
                        <div style="font-weight:550" v-if="item.zrtype == 1">-{{ returnFloat(item.value) }}</div>
                        <div style="font-weight:550" v-if="item.zrtype == 3">{{ returnFloat(item.value) }}</div>
                    </div>
                    <div class="time">{{ item.transferTime }}</div>
                </div>
            </div>
        </div>

    </div>



</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
          currentYear: "",
          currentMonth: ""
        }
    },
    computed: {
        ...mapState({
            list: state => state.billList
        })
    },
      mounted() {
        this.getCurrentYearMonth();
      },    
    methods: {
     getCurrentYearMonth() {
          const now = new Date();
          this.currentYear = now.getFullYear();
          // 月份是从 0 开始计数的，所以需要加 1
          this.currentMonth = now.getMonth() + 1;
        },  
        returnFloat(num) {
             console.log(num)
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
     
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    },
        toBillDetail(id) {
            this.$router.push({ path: '/self/service/bill/billList/detail', query: { id } })
        }
    }
}


</script>
<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    .title {
        flex: 1;
        margin-left: 17px;
    }
    .bill {
        font-weight: 400;
        font-size: 14px;
    }
}
.statistics-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 20px;
    .bill {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 6px;
        border-radius: 20px;
        background: #E3E3E3;
        img {
            width: 10px;
            height: 10px;
            margin-left: 4px;

        }
    }
    .statistics {
        display: flex;
        align-items: center;
        color: #afafaf;
        .van-icon {
            margin-top: -2px;
        }
    }
}
.time-con {
    width: 100%;
    height: 50px;
    border-top: 1px solid #dbdada;
    display: flex;
    justify-content: space-between;
    .time {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .van-icon {
            margin-top: -2px;
        }
    }
    .cost {
        display: flex;
        align-items: center;
        margin-right: 14px;
        font-size: 10px;
        color: #afafaf;
        div:nth-of-type(1) {
            margin-right: 8px;
        }
    }
}
.list {
    background: #fff;
    height: calc(~'100% - 170px');
    overflow-y: auto;
    .item {
        display: flex;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-left: 10px;
        }
        .text {
            flex: 1;
            margin-left: 10px;
            padding-right: 14px;
            .first {
                display: flex;
                display: flex;
                justify-content: space-between;
            }
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e9e9e9;
        }
        .time {
            color: #afafaf;
            font-size: 10px;
        }
    }

}
</style>